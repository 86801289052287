@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Fira+Sans+Condensed&display=swap');

html{
    font-size: 16px;
}

body{
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}
nav ul li{
    font-size: 1.2em;
}
.title{
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 3rem;
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 0;
}
.video{
    position: relative;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    opacity: 0.6;
}
div.video-container{
    height: 100vh;
}
.video-container::before{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80jpg"); 
    background-size: cover;
    opacity: 0.3;
    z-index: 0;
}
.top{
    z-index: 1000;
    position: absolute;
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.body{
    z-index: 0;
}
.bar{
    height: 4px;
    width: 70px;
    background-color: #333;
    margin: 0 auto 40px;
}
#navbar{
    height: 100px;
}
nav{
    width: 100%;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
nav ul{
    display: flex;
    justify-content: space-between;
}
nav ul li{
    padding: 0 10px;
}
a, a:active, a:visited, a:focus, a:hover{
    color: #FFF;
}
.logo{
    width: 50px;
    position: absolute;
    left: 40px;
    top: 21px;
}
.logo2{
    width: 100px;
}
.logo-footer{
    width: 100px;
    margin-top: 20px;
}
h1{
    margin-top: 14px;
}
h5{
    margin: 5px 0 20px;
    color: #FFF;
}
h6{
    padding-bottom: 20px;
    margin-bottom: 0;
}
.tagline{
    text-align: center;
    color: #FFF;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    z-index: 999;
    h1{
        margin-bottom: 5px;
    }
    h3{
        margin-top: 0px;
    }
}
.about-me, .portfolio, .contact{
    margin: 40px 0 20px;
}
.skills{
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
    text-align: center;    
    flex-wrap: wrap;
}
.skills div ul{
    padding: 0 !important;
}
.skills div{
    margin: 20px 0;
}
ul{
    list-style-type: none;
}
#about{
    padding: 100px 0;
}
#portfolio{
    background-color: #59C1F4;
    padding: 100px 0;
}
#portfolio h1.title{
    color: #FFF;
}
#portfolio div.bar{
    background-color: #FFF;
}
#portfolio div.column img, #portfolio div.ui.header{
    margin: 0 auto;
}
#contact{
    padding: 100px 0;
}
button.ui.button{
    background-color: #FF668E;
    color: #FFF;
}
button.ui.button:hover{
    background-color: #FF668E;
    color: #FFF;
}
i:hover{
    color: #59C1F4;
    transition: 0.5s;
}
.field label{
    display: none !important;
}
.up{
    position: relative;
    top: -10px;
    width: 28px;
    height: 30px;
    background-color: #FF668E;
    margin: 0 auto;
    padding-top: 5px;
}
.footer{
    text-align: center;
    background: #333;
    color: #FFF;
    bottom: 0;
}
#navbar {
    position: fixed;
    transition: 0.5s;
    
    #visible{
        position: absolute;
        left: 100px;
        top: 28px;
    }
}
.skills div i{
    text-align: center;
    margin: 0 auto;
}
.skills div i:hover{
    color: #333;
}
.skills div h2{
    margin-top: 5px;
}
.skills div div.bar{
    margin: 0 auto 10px;
    
}
.image-btn{
    display: none!important;
}
.img:hover + .image-btn, .image-btn:hover {
    display: inline-block!important;
}
span{ 
    a, a:active, a:visited, a:focus, a:hover{
        color: #59C1F4;
    }
}
.hidden-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    padding-top: 15px;
    img{
        margin-right: 10px;
    }
    span{
        visibility: hidden;
    }
}
div.description{
    ul{
        list-style-position: outside!important;
        list-style-type: disc;
        padding-left: 30px!important;
    }
    a{
        color:#59C1F4!important;
    }
}
.techstack{
    display: flex;
    .frontend, .backend, .testing{
        width: 50%;
    }
}

.mobile-navbar{
    #mobile-nav{
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 50px;
        top: 65px;
        text-align: right;
        li{
            padding: 8px 0;
            a{
                &:hover{
                    color: #FF668E;
                }
            }
        }
    }
}
.mobile-menu{
    color: #FFF;
    position: absolute;
    right: 40px;
    top: 35px;
}
img.logo{
    position: absolute;
    left: 40px;
    top: 21px;
}

button.positive{
    background-color: #FF668E!important;
}